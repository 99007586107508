<template>
  <div>
    <h1 class="title-patternOne text-primary mb-3">Valide seu e-mail</h1>
    <p class="text-patternTwo color-gray1">
      Insira o código de validação enviado para o seu e-mail.
    </p>
    <b-form @submit.prevent="validateEmail()">
      <div class="d-flex justify-content-between">
        <b-form-group
          v-for="(inputPosition, index) in inputCodeArr"
          :key="index"
        >
          <b-form-input
            type="text"
            size="lg"
            required
            v-model="formValidateEmail[inputPosition]"
            inputmode="numeric"
            ref="inputCodeRef"
            :formatter="inputFormatter"
            @click="$refs.inputCodeRef[index].$el.setSelectionRange(1, 1)"
            @keydown="keyDownEmailCode($event, index)"
            @input="inputEmailCode($event, index)"
            @paste="pasteEmailCode($event)"
          ></b-form-input>
        </b-form-group>
      </div>
      <p class="text-patternOne color-gray1">
        <span>
          <span
            v-if="
              emailCodeTimer.includes('minuto') ||
              emailCodeTimer.includes('segundo')
            "
            >Código válido por</span
          >
          <span
            :class="{
              'color-black2':
                emailCodeTimer.includes('minuto') ||
                emailCodeTimer.includes('segundo'),
            }"
          >
            {{ emailCodeTimer }}
          </span>
        </span>
        <a
          :style="
            this.emailCodeTimer.includes('minuto') ||
            this.emailCodeTimer.includes('segundo')
              ? 'opacity: 0.5; cursor: not-allowed;'
              : null
          "
          class="text-primary"
          @click="resendCodeToEmail()"
          v-if="!feedbackResendCode"
        >
          Reenviar código
        </a>
        <span class="text-primary" style="cursor: default" v-else>
          {{ feedbackResendCode }}
        </span>
      </p>
      <error-message :error="feedbackGeneralError" />
      <b-form-group>
        <b-button
          type="submit"
          variant="secondary"
          size="lg"
          :disabled="loadingButton"
          ><b-spinner large v-if="loadingButton"></b-spinner
          ><span v-else>Validar</span></b-button
        >
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
export default {
  data: () => ({
    loadingButton: false,
    inputCodeArr: ['one', 'two', 'three', 'four', 'five', 'six'],
    formValidateEmail: {
      one: '',
      two: '',
      three: '',
      four: '',
      five: '',
      six: '',
    },
    emailCodeTimer: '',
    intervalEmailCodeTimer: '',
    validationCodeExpiredTimeInMinutes: 10,
    feedbackResendCode: '',
    feedbackGeneralError: {
      status: 0,
      message: [],
      errorDictionary: [],
      data: {},
    },
  }),
  methods: {
    validateEmail() {
      this.loadingButton = true;
      let codeValidateEmailConcat = this.formValidateEmail.one
        .concat(this.formValidateEmail.two)
        .concat(this.formValidateEmail.three)
        .concat(this.formValidateEmail.four)
        .concat(this.formValidateEmail.five)
        .concat(this.formValidateEmail.six);
      let payload = {
        accountId: this.$store.state.accountId,
        code: codeValidateEmailConcat,
      };
      this.$onboardingService
        .validateEmail(payload)
        .then((response) => {
          this.$store.state.accountId = '';
          localStorage.removeItem('account_id');
          localStorage.removeItem('totalCodeTimeValidateEmail');
          localStorage.setItem('account_token', response.data.token);
          this.$router.push('/user-link-conditions');
        })
        .catch((error) => {
          this.loadingButton = false;
          this.feedbackGeneralError = error;
        });
    },
    countDownTimer() {
      let totalTime;
      let totalTimeStorage = localStorage.getItem('totalCodeTimeValidateEmail');
      if (!totalTimeStorage) {
        totalTime = new Date();
        totalTime.setMinutes(
          totalTime.getMinutes() + this.validationCodeExpiredTimeInMinutes
        );
        totalTime = totalTime.getTime();
        localStorage.setItem('totalCodeTimeValidateEmail', totalTime);
      } else {
        totalTime = totalTimeStorage;
      }

      this.intervalEmailCodeTimer = setInterval(() => {
        let currentTime = new Date().getTime();
        let difference = totalTime - currentTime;

        let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((difference % (1000 * 60)) / 1000);

        if (minutes > 0 && seconds > 0)
          this.emailCodeTimer = `${minutes} minuto${
            minutes == 1 ? '' : 's'
          } e ${seconds} segundo${seconds == 1 ? '' : 's'}`;
        else if (minutes > 0 && seconds == 0)
          this.emailCodeTimer = `${minutes} minuto${minutes == 1 ? '' : 's'}`;
        else if (seconds > 0)
          this.emailCodeTimer = `${seconds} segundo${seconds == 1 ? '' : 's'}`;
        else {
          this.emailCodeTimer =
            'O tempo do código foi expirado! Clique em reenviar código.';
          clearInterval(this.intervalEmailCodeTimer);
        }
      }, 1000);
    },
    resendCodeToEmail() {
      if (
        !this.emailCodeTimer.includes('minuto') &&
        !this.emailCodeTimer.includes('segundo')
      ) {
        let payload = {
          accountId: this.$store.state.accountId,
        };
        clearInterval(this.intervalEmailCodeTimer);
        this.feedbackResendCode = 'Enviando...';
        this.$onboardingService
          .resendEmailCode(payload)
          .then((response) => {
            this.feedbackResendCode = 'Enviado com sucesso!';
            localStorage.removeItem('totalCodeTimeValidateEmail');
            this.countDownTimer();
            setTimeout(() => {
              this.feedbackResendCode = '';
            }, 4000);
          })
          .catch((error) => {
            this.feedbackResendCode = '';
            this.feedbackGeneralError = error;
          });
      }
    },
    keyDownEmailCode(event, index) {
      if (
        event.key == 'Backspace' &&
        index > 0 &&
        !this.$refs.inputCodeRef[index].$el.value
      )
        this.$refs.inputCodeRef[index - 1].$el.focus();
      else if (event.key == 'ArrowLeft' && index > 0) {
        this.$refs.inputCodeRef[index - 1].$el.focus();
        setTimeout(() => {
          this.$refs.inputCodeRef[index - 1].$el.setSelectionRange(1, 1);
        }, 0.5);
      } else if (
        index + 1 < this.$refs.inputCodeRef.length &&
        event.key == 'ArrowRight'
      )
        this.$refs.inputCodeRef[index + 1].$el.focus();
    },
    inputEmailCode(event, index) {
      if (
        index + 1 < this.$refs.inputCodeRef.length &&
        event.key != 'Backspace' &&
        event.key != 'ArrowLeft' &&
        this.$refs.inputCodeRef[index].$el.value
      )
        this.$refs.inputCodeRef[index + 1].$el.focus();
    },
    pasteEmailCode(event) {
      const clip = event.clipboardData.getData('text').trim();

      if (!/\d{6}/.test(clip)) return event.preventDefault();

      const clippedArr = [...clip];

      this.inputCodeArr.forEach((element, index) => {
        this.formValidateEmail[element] = clippedArr[index];
      });

      this.$refs.inputCodeRef[5].$el.focus();
    },
    inputFormatter(value) {
      if (/^[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]$/.test(value))
        return '';
      else return value;
    },
  },
  created() {
    if (!this.$store.state.accountId)
      this.$store.state.accountId = localStorage.getItem('account_id');

    if (!this.$store.state.accountId && !localStorage.getItem('account_id'))
      this.$router.push(`/verify-email/${localStorage.getItem('access_token')}`);

    this.countDownTimer();
  },
  mounted() {
    this.$refs.inputCodeRef.forEach((element) => {
      element.$el.maxLength = 1;
    });
  },
};
</script>

<style lang="scss" scoped>
@mixin input-code($width, $height, $font-size) {
  width: $width;
  height: $height;
  font-size: $font-size;
}

form {
  margin-top: 5rem;
  > div.d-flex {
    .form-group {
      input {
        // border: 4px solid #D0D0D0;
        // &:focus {
        //   border-color: #3efffb;
        // }
        @include input-code(115px, 160px, 90px);
        @media (max-width: 991px) and (min-width: 769px) {
          @include input-code(90px, 135px, 65px);
        }
        @media (max-width: 768px) and (min-width: 635px) {
          @include input-code(75px, 120px, 50px);
        }
        @media (max-width: 634px) and (min-width: 520px) {
          @include input-code(60px, 105px, 45px);
        }
        @media (max-width: 519px) and (min-width: 390px) {
          @include input-code(50px, 70px, 25px);
        }
        @media (max-width: 389px) {
          @include input-code(45px, 60px, 18px);
        }
      }
    }
  }

  p {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    a {
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        color: var(--primary) !important;
      }
    }
  }

  .btn {
    margin-top: 6.5rem;
  }

  @media (max-width: 991px) {
    p {
      flex-direction: column;
      text-align: left;
      span:first-child {
        margin-bottom: 0.7rem;
      }
    }

    .btn {
      margin-top: 4rem;
    }
  }
}
</style>