var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title-patternOne text-primary mb-3"},[_vm._v("Valide seu e-mail")]),_c('p',{staticClass:"text-patternTwo color-gray1"},[_vm._v(" Insira o código de validação enviado para o seu e-mail. ")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateEmail()}}},[_c('div',{staticClass:"d-flex justify-content-between"},_vm._l((_vm.inputCodeArr),function(inputPosition,index){return _c('b-form-group',{key:index},[_c('b-form-input',{ref:"inputCodeRef",refInFor:true,attrs:{"type":"text","size":"lg","required":"","inputmode":"numeric","formatter":_vm.inputFormatter},on:{"click":function($event){return _vm.$refs.inputCodeRef[index].$el.setSelectionRange(1, 1)},"keydown":function($event){return _vm.keyDownEmailCode($event, index)},"input":function($event){return _vm.inputEmailCode($event, index)},"paste":function($event){return _vm.pasteEmailCode($event)}},model:{value:(_vm.formValidateEmail[inputPosition]),callback:function ($$v) {_vm.$set(_vm.formValidateEmail, inputPosition, $$v)},expression:"formValidateEmail[inputPosition]"}})],1)}),1),_c('p',{staticClass:"text-patternOne color-gray1"},[_c('span',[(
            _vm.emailCodeTimer.includes('minuto') ||
            _vm.emailCodeTimer.includes('segundo')
          )?_c('span',[_vm._v("Código válido por")]):_vm._e(),_c('span',{class:{
            'color-black2':
              _vm.emailCodeTimer.includes('minuto') ||
              _vm.emailCodeTimer.includes('segundo'),
          }},[_vm._v(" "+_vm._s(_vm.emailCodeTimer)+" ")])]),(!_vm.feedbackResendCode)?_c('a',{staticClass:"text-primary",style:(this.emailCodeTimer.includes('minuto') ||
          this.emailCodeTimer.includes('segundo')
            ? 'opacity: 0.5; cursor: not-allowed;'
            : null),on:{"click":function($event){return _vm.resendCodeToEmail()}}},[_vm._v(" Reenviar código ")]):_c('span',{staticClass:"text-primary",staticStyle:{"cursor":"default"}},[_vm._v(" "+_vm._s(_vm.feedbackResendCode)+" ")])]),_c('error-message',{attrs:{"error":_vm.feedbackGeneralError}}),_c('b-form-group',[_c('b-button',{attrs:{"type":"submit","variant":"secondary","size":"lg","disabled":_vm.loadingButton}},[(_vm.loadingButton)?_c('b-spinner',{attrs:{"large":""}}):_c('span',[_vm._v("Validar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }